import React, { useState, useEffect } from 'react'
import List from "../components/Lists/List"
import store from "../utils/Data"
import StoredApi from "../utils/StoredAPI"
import { v4 as uuid } from 'uuid'
import InputConainer from './Input/InputConainer'
import { makeStyles } from "@material-ui/core/styles";
import {message, Modal} from 'antd';

import axios from "../utils/Axios"

import { DragDropContext, Droppable} from "react-beautiful-dnd";

import { getArchivadasCards,activarCard } from '../firebase/queryProvider';
import ListArchivados from './Lists/ListArchivados'



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
        overflowX: 'scroll',
        height: '90vh'
        },
    listbg: {
        width: '300px',
        backgroundColor: '#EBECF0',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}))



const Archivados = () => {

    const classes = useStyles();
    const [lists, setLists] = useState([])
    const [listIDs, setListIDs] = useState([])

    const user = JSON.parse(localStorage.getItem('DBUSER'));

    useEffect(async () => {

        const result = await getArchivadasCards();

        console.log("result",result)

        if(result.ok){
            setLists(result.listado)
        }else{
            message.error('¡Hubo un problema!');
        }

        /*axios.get(`/user/${user.email}`)
        .then(res => {
            setLists(res.data.lists)
        })*/
    }, [])
    
    const updateListTitle = (title, index) => {
        
    }

    const reActivarCard = async(listIndex, cardIndex) => {
        const allListsCopy = [...lists];
        const allCardsCopy = [...allListsCopy[listIndex].cards];
        await activarCard(allCardsCopy[cardIndex].id)
        allCardsCopy.splice(cardIndex, 1);
        allListsCopy[listIndex].cards = allCardsCopy;
        setLists(allListsCopy);
    }

    return (

        <StoredApi.Provider value={{ updateListTitle,reActivarCard}}>
            <DragDropContext>

                <Droppable droppableId='list' type='list' direction='horizontal'>

                    {(provided) => (

                        <div className={classes.root}
                            ref={provided.innerRef} {...provided.droppableProps}
                        >

                            {lists && lists.map((list,index) => {
                                return <ListArchivados list={list} key={list._id} index={index}/>
                            })}

                            
                            {provided.placeholder}
                        </div>
                    )}

                </Droppable>

            </DragDropContext>


        </StoredApi.Provider>
    )
}

export default Archivados
