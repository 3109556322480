import React, {useState} from 'react'
import "./Title.css"

import { Paper, CssBaseline } from "@material-ui/core";
import { makeStyles,fade } from "@material-ui/core/styles";

import Title from './Title';
import Card from '../Card/Card';
import InputConainer from '../Input/InputConainer';
import { Resizable } from 're-resizable';
import { cambiarAncho } from '../../firebase/queryProvider';


import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#EBECF0',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        
    },
    cardContainer: {
        marginTop: theme.spacing(4),
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    resizable: {
        cursor: 'ew-resize',
        backgroundColor:"#fff",
        marginRight: "20px",
        width: "520px",
        borderRadius: '7px',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), ' +
        '0px 1px 1px 0px rgba(0, 0, 0, 0.14), ' +
        '0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        },
}))


const List = ({ list, index }) => {
    const classes = useStyles();
    //console.log("list",list)


    const modificarAncho = async(ancho, id) => {
        await cambiarAncho(ancho,id)
    }

    return (

    

        <Resizable
            className={classes.resizable}
            defaultSize={{
                height: "auto",
                width: list.ancho
            }}
            onResizeStop={(e, direction, ref, d) => {
                const newWidth = parseInt(ref.style.width);
                console.log("ancho total", newWidth,list._id);
                modificarAncho(newWidth,list._id)
                //guardar ancho de esta columna
            }}
            >
                <div 
                    elevation={0}
                    id={list._id} index={index}>
                    <CssBaseline />
                    <Title title={list.title} index={index} />
                 
                    <Droppable droppableId={list._id}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef} {...provided.droppableProps}
                                className={classes.cardContainer}
                            >
                                {
                                    list.cards && list.cards.map((card, i) => (
                                        <Card key={card.id} card={card} cardIndex={i}
                                        listIndex={index} type="listado" />
                                    ))
                                }
                                {provided.placeholder}
                                
                            </div>
                        )}

                    </Droppable>


                    <InputConainer listID={list._id} index={index} type={'card'} />
                </div>
        </Resizable>


       
        
    )
}

export default List
