const colors = [
    '#FFFF00',
    '#FF0000',
    '#808080',
    '#008080',
    '#000080',
    '#FF5733',
    '#00FF66'
]


export default colors;