import React, { useState, useEffect } from 'react'
import List from "../components/Lists/List"
import store from "../utils/Data"
import StoredApi from "../utils/StoredAPI"
import { v4 as uuid } from 'uuid'
import InputConainer from './Input/InputConainer'
import { makeStyles } from "@material-ui/core/styles";
import {message, Modal} from 'antd';

import axios from "../utils/Axios"

import { DragDropContext, Droppable} from "react-beautiful-dnd";

import { getLista,archivarCard,entrarSalirCard,cambiarPosicionCard } from '../firebase/queryProvider';



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
        overflowX: 'scroll',
        height: '90vh'
        },
    listbg: {
        width: '300px',
        backgroundColor: '#EBECF0',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}))



const Home = () => {

    const classes = useStyles();
    const [lists, setLists] = useState([])
    const [listIDs, setListIDs] = useState([])

    const user = JSON.parse(localStorage.getItem('DBUSER'));

    useEffect(async () => {

        const result = await getLista();
        if(result.ok){
            console.log("===",result.listado)
            setLists(result.listado)
        }else{
            message.error('¡Hubo un problema!');
        }

        /*axios.get(`/user/${user.email}`)
        .then(res => {
            setLists(res.data.lists)
        })*/
    }, [])

    const addMoreCard = (title, index) => {
        const date = new Date();
        
        const newCard = {
            date: `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`,
            content: 'Add your content here..',
            title: title,
        }

        const reqList = lists[index];
        reqList.cards = [...reqList.cards, newCard]

        const modList = {
            ...reqList,
            cards: reqList.cards
        }

        const allLists = [...lists];
        allLists[index] = modList;
        axios.put(`/upload/card/${user._id}`, { lists: allLists })
       

    }

    const updateCardContent = (content, listIndex, cardIndex) => {

        lists[listIndex].cards[cardIndex].content = content;
        let allLists = [...lists];
        
        axios.put(`/upload/card/${user._id}`, { lists: allLists })
        
    }


    const finalizarCard = async(listIndex, cardIndex) => {
        const allListsCopy = [...lists];
        const allCardsCopy = [...allListsCopy[listIndex].cards];
        await archivarCard(allCardsCopy[cardIndex].id)
        allCardsCopy.splice(cardIndex, 1);
        allListsCopy[listIndex].cards = allCardsCopy;
        setLists(allListsCopy);
    }

    

    const cambiarStatus = async(listIndex, cardIndex, status) => {
        const allListsCopy = [...lists];
        const targetCard = allListsCopy[listIndex].cards[cardIndex];
        await entrarSalirCard(targetCard.id,status) // Asegúrate de que esta función cambia el estado de la tarjeta en la base de datos
        targetCard.status = status; 
        setLists(allListsCopy);
        console.log("aqui", targetCard.id)

    }
    
    


    const updateListTitle = (title, index) => {
        const list = lists[index];
        list.title = title;
        const allLists = [...lists];
        //axios.put(`/upload/list/${user._id}`, { lists: allLists })
        
    }

   
    const onDragEndss = async(result) => {
        const { destination, source, draggableId, type } = result;
    
        if (!destination) {
            return;
        }

    
        const sourceListIndex = lists.findIndex(
            (list) => list._id === source.droppableId
        );
        const draggingCard = lists[sourceListIndex].cards[source.index];
    
        const updatedList = [...lists];
        updatedList[sourceListIndex].cards.splice(source.index, 1);
        updatedList[sourceListIndex].cards.splice(destination.index, 0, draggingCard);
    
        // Actualizar las posiciones de las tarjetas
        for (let i = 0; i < updatedList[sourceListIndex].cards.length; i++) {
            updatedList[sourceListIndex].cards[i].position = i;
        }
    
        
        await cambiarPosicionCard(updatedList[sourceListIndex].cards);
    };

    const onDragEnd = async (result) => {
        const { destination, source, draggableId, type } = result;
      
        if (!destination) {
          return;
        }
      
        const sourceListIndex = lists.findIndex((list) => list._id === source.droppableId);
        const destinationListIndex = lists.findIndex((list) => list._id === destination.droppableId);
      
        const draggingCard = lists[sourceListIndex].cards[source.index];
      
        if (source.droppableId === destination.droppableId) {
          // Mover dentro de la misma columna
          const updatedList = [...lists];
          updatedList[sourceListIndex].cards.splice(source.index, 1);
          updatedList[sourceListIndex].cards.splice(destination.index, 0, draggingCard);
      
          // Actualizar las posiciones de las tarjetas en la misma columna
          for (let i = 0; i < updatedList[sourceListIndex].cards.length; i++) {
            updatedList[sourceListIndex].cards[i].position = i;
          }
      
          await cambiarPosicionCard(updatedList[sourceListIndex].cards,updatedList[sourceListIndex].title);
        } else {
          // Mover a otra columna
          const updatedSourceList = [...lists];
          updatedSourceList[sourceListIndex].cards.splice(source.index, 1);
      
          const updatedDestinationList = [...lists];
          updatedDestinationList[destinationListIndex].cards.splice(destination.index, 0, draggingCard);
      
          // Actualizar las posiciones de las tarjetas en la columna de origen
          for (let i = 0; i < updatedSourceList[sourceListIndex].cards.length; i++) {
            updatedSourceList[sourceListIndex].cards[i].position = i;
          }
      
          // Actualizar las posiciones de las tarjetas en la columna de destino
          for (let i = 0; i < updatedDestinationList[destinationListIndex].cards.length; i++) {
            updatedDestinationList[destinationListIndex].cards[i].position = i;
          }

          await Promise.all([
            cambiarPosicionCard(updatedSourceList[sourceListIndex].cards,updatedSourceList[sourceListIndex].title),
            cambiarPosicionCard(updatedDestinationList[destinationListIndex].cards,updatedDestinationList[destinationListIndex].title),
          ]);
        }
      };
      

    const onDragEndD = (result) => {
        const { destination, source, draggableId, type } = result;

        if (!destination) {
            return;
        }
    
        if (type === 'list') {
            const tempList = lists[source.index];
            lists[source.index] = lists[destination.index];
            lists[destination.index] = tempList;
            const allLists = [...lists];
            //axios.put(`/upload/card/${user._id}`, { lists: allLists });
            console.log("regreso 2")
            return;
        }
    
        //console.log("type",type,lists)


        const destinationListIndex = lists.findIndex(
            (list) => list._id === destination.droppableId
        );
        const sourceListIndex = lists.findIndex(
            (list) => list._id === source.droppableId
        );
        const draggingCard = lists[sourceListIndex].cards[source.index];
    
        if (source.droppableId === destination.droppableId) {

            const updatedList = [...lists];
            console.log("====== 1",updatedList)

            updatedList[sourceListIndex].cards.splice(source.index, 1)
            updatedList[destinationListIndex].cards.splice(destination.index, 0, draggingCard);
            console.log("====== 2",updatedList)

            //axios.put(`/upload/card/${user._id}`, { lists: updatedList });
        } else {
            console.log("type","entro 2")

            const updatedSourceList = [...lists[sourceListIndex].cards];
            const updatedDestinationList = [...lists[destinationListIndex].cards];
            updatedSourceList.splice(source.index, 1);
            updatedDestinationList.splice(destination.index, 0, draggingCard);
    
            const allLists = [...lists];
            allLists[sourceListIndex].cards = updatedSourceList;
            allLists[destinationListIndex].cards = updatedDestinationList;
            //axios.put(`/upload/card/${user._id}`, { lists: allLists });
        }
    }

    return (

        <StoredApi.Provider value={{ addMoreCard, updateListTitle, 
            updateCardContent, finalizarCard,cambiarStatus }}>
            <DragDropContext onDragEnd={onDragEnd}>

                <Droppable droppableId='list' type='list' direction='horizontal'>

                    {(provided) => (

                        <div className={classes.root}
                            ref={provided.innerRef} {...provided.droppableProps}
                        >

                            {lists && lists.map((list,index) => {
                                return <List list={list} key={list._id} index={index}/>
                            })}

                            
                            {provided.placeholder}
                        </div>
                    )}

                </Droppable>

            </DragDropContext>


        </StoredApi.Provider>
    )
}

export default Home
