import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth';
import { FirebaseAuth,FirebaseDB } from './config';
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";



export const registerUserWithEmailPassword = async({ email, password, displayName }) => {

    try {
        const resp = await createUserWithEmailAndPassword( FirebaseAuth, email, password );
        const { uid, photoURL } = resp.user;

        await updateProfile( FirebaseAuth.currentUser, { displayName });

        return {
            ok: true,
            uid, photoURL, email, displayName
        }

    } catch (error) {
        console.log(error);
        return { ok: false, errorMessage: error.message }
    }

}


export const loginWithEmailPassword = async({ user, password }) => {

    try {
        var email = user + "@proembasa.com"
        const collectionRef = collection( FirebaseDB, `Usuario`);
        const queryMessages = query(collectionRef, 
            where("correo", "==", email),
            where("clave", "==", password),
            );
        const querySnapshot = await getDocs(queryMessages);
       
        if(querySnapshot.size == 0){
            return {
                ok: false,
                errorMessage: "Credenciales no validas"
            }
        }

        var rol = ""
        var apellido = ""
        var nombre = ""
        var idUser = ""
        
        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            idUser = documment.id
            rol = data.rol
            apellido = data.apellido
            nombre = data.nombre
        });

       
        try {
            await signInWithEmailAndPassword( FirebaseAuth, email, password );
            console.log("Inicio de sesión exitoso");
        } catch (error) {
            console.log(error.code, error.message);
            await createUserWithEmailAndPassword( FirebaseAuth, email, password );
        }

        
        return {
            ok: true,
            rol, apellido, nombre, idUser, email
        }
        
        

        //const data = await getQueryDocByCollectionAnId("profile", "user_id" , "==", id);

        /*const resp = await signInWithEmailAndPassword( FirebaseAuth, email, password );
        const { uid, photoURL, displayName } = resp.user;

        return {
            ok: true,
            uid, photoURL, displayName
        }*/

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }
}

export const logoutFirebase = async() => {
    return await FirebaseAuth.signOut();
}