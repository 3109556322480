import { FirebaseAuth,FirebaseDB,FirebaseBatch} from './config';
import { collection, query, where, getDocs, doc, getDoc, setDoc,limit,orderBy,updateDoc,serverTimestamp} from "firebase/firestore";
import moment from 'moment';

export const getLista = async() => {

     try {
        var listado = []
        const collectionRef = collection( FirebaseDB, `Columnas`);
        var queryMessages = query(collectionRef, 
            where("activo", "==", true),
            orderBy("created_at","desc")
        );
        
        const querySnapshot = await getDocs(queryMessages);
    
        if(querySnapshot.size == 0){
            return {
                ok: true,
                listado: []
            }
        }

        const promises = [];

        querySnapshot.forEach((document) => {
            let data = document.data();
            data._id = document.id;

            const promise = getCards(data.title).then((resultCard) => {
                data.cards = resultCard.listado;
                listado.push(data);
            });

            promises.push(promise);
        });

        await Promise.all(promises);
        
        return {
            ok: true,
            listado:listado
        }

    } catch (error) {
        console.log("listado",error)
        return { ok: false, errorMessage: error.message }

    }
}



function limitAndPad(text, maxLength) {
    
    if (text && text.length > maxLength) {
        return text.substring(0, maxLength);
    }

    while (text && text.length < maxLength) {
        text += " ";
    }
   
    return text;
}


export const activarCard = async(id) => {
    try {
        const cardRef = doc(FirebaseDB, 'Tarjetas', id); // Obtiene la referencia del documento

        // Primero, obtén el documento para verificar si existe y qué contiene
        const cardSnapshot = await getDoc(cardRef);

        if (!cardSnapshot.exists()) {
            console.log(`No document found with id ${id}`);
            return { ok: false }
        }

        // Ahora intenta actualizar el documento
        await updateDoc(cardRef, {
            finalizado: false,
            position: getCurrentTimestamp(),
            fechaFinalizado: serverTimestamp()
        }); // Actualiza el estado a "archivado"

        console.log(`Document with id ${id} updated`);

        return {
            ok: true
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false }
    }
}

const getCurrentTimestamp = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();
  
    // Concatenar los componentes de la fecha y hora en un solo número
    const timestamp = parseInt(`${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`);
  
    return timestamp;
};

export const archivarCard = async(id) => {
    try {
        const cardRef = doc(FirebaseDB, 'Tarjetas', id); // Obtiene la referencia del documento

        // Primero, obtén el documento para verificar si existe y qué contiene
        const cardSnapshot = await getDoc(cardRef);

        if (!cardSnapshot.exists()) {
            console.log(`No document found with id ${id}`);
            return { ok: false }
        }

        console.log(`Document with id ${id} found, data: `, cardSnapshot.data());

        // Ahora intenta actualizar el documento
        await updateDoc(cardRef, {
            finalizado: true,
            fechaFinalizado: serverTimestamp(),
            status: "pendiente"
        }); // Actualiza el estado a "archivado"

        console.log(`Document with id ${id} updated`);

        return {
            ok: true
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false }
    }
}

export const cambiarPosicionCard  = async (cards,columna) => {
    try {

        const batchUpdate = FirebaseBatch(FirebaseDB);

        // Crea todas las operaciones de actualización en la misma operación por lotes
        for (let card of cards) {
            const cardRef = doc(FirebaseDB, 'Tarjetas', card.id);
            batchUpdate.update(cardRef, {
                position: card.position,
                columna: columna,
                fechaModificado: serverTimestamp()
            });
        }
    
        // Ejecuta todas las actualizaciones como una sola operación atómica
        await batchUpdate.commit();

        return {
            ok: true
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false }
    }
}



export const cambiarAncho = async(ancho,id) => {
    try {
        const cardRef = doc(FirebaseDB, 'Columnas', id); // Obtiene la referencia del documento

        // Primero, obtén el documento para verificar si existe y qué contiene
        const cardSnapshot = await getDoc(cardRef);

        if (!cardSnapshot.exists()) {
            console.log(`No document found with id ${id}`);
            return { ok: false }
        }

        // Ahora intenta actualizar el documento
        await updateDoc(cardRef, {
            ancho: ancho
        }); // Actualiza el estado a "archivado"

        return {
            ok: true
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false }
    }
}

export const entrarSalirCard = async(id,status) => {
    try {
        const cardRef = doc(FirebaseDB, 'Tarjetas', id); // Obtiene la referencia del documento

        // Primero, obtén el documento para verificar si existe y qué contiene
        const cardSnapshot = await getDoc(cardRef);

        if (!cardSnapshot.exists()) {
            console.log(`No document found with id ${id}`);
            return { ok: false }
        }

        console.log(`Document with id ${id} found, data: `, cardSnapshot.data());

        // Ahora intenta actualizar el documento
        await updateDoc(cardRef, {
            status: status
        }); // Actualiza el estado a "archivado"

        console.log(`Document with id ${id} updated`);

        return {
            ok: true
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false }
    }
}
  
export const getCards = async(idColumna) => {

    try {
        var listado = []
        const collectionRef = collection( FirebaseDB, `Tarjetas`);

        var queryMessages = query(collectionRef, 
            where("finalizado", "==", false),
            where("columna", "==", idColumna),
            orderBy("position","asc")
        );

        const querySnapshot = await getDocs(queryMessages);
        if(querySnapshot.size == 0){
            return {
                listado: []
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            data.cliente = limitAndPad(data.cliente, 20);
            data.destino = limitAndPad(data.destino, 20);
            data.qualitat = limitAndPad(data.qualitat, 30);
            data.gramatge = limitAndPad(data.gramatge, 3);
            data.ampleBobina = limitAndPad(data.ampleBobina, 5);
            data.ampleTall = limitAndPad(data.ampleTall, 4);
            data.llargTall = limitAndPad(data.llargTall, 5);
            data.numFulls = limitAndPad(data.numFulls, 7);
            data.kilos = limitAndPad(data.kilos, 7);
            data.dataEntrega = limitAndPad(data.dataEntrega, 5);
            data.numOF = limitAndPad(data.numOF, 6);
            data.observacions = limitAndPad(data.observacions, 30);
            if(data.fechaModificado != undefined){
                data.fechaModificadoView =  moment( data.fechaModificado.toDate()).format("DD/MM/Y HH:mm:ss")
            }

           
            //delete data.id;
            delete data.fechaCreado;
            
            listado.push(data)
        });

        console.log("listado",listado)

        //reordenar
        listado.sort((a, b) => {
            // Si ambos tienen la misma posición, compara por fechaModificado
            if(a.position === b.position) {
                if(a.fechaModificado && b.fechaModificado) {
                    return b.fechaModificado - a.fechaModificado; // Ordena de más reciente a más antiguo
                }
                else if(a.fechaModificado) {
                    return -1;
                }
                else if(b.fechaModificado) {
                    return 1;
                }
                else {
                    return 0; // Si tienen la misma posición y no tienen fechaModificado, se quedan en el mismo lugar
                }
            }
            else {
                // Si tienen diferentes posiciones, se ordenan por position
                return a.position - b.position; // Ordena de menor a mayor
            }
        });
        
        return {
            ok: true,
            listado:listado
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false, listado: [] }
    }
    

}

export const getArchivadasCards = async() => {

    try {
        var listado = []
        const collectionRef = collection( FirebaseDB, `Tarjetas`);

        var queryMessages = query(collectionRef, 
            where("finalizado", "==", true),
            orderBy("fechaFinalizado","asc")
        );

        const querySnapshot = await getDocs(queryMessages);
        if(querySnapshot.size == 0){
            let data = {};
            data._id = "1";
            data.ancho = "100%"
            data.title = "Archivados"
            data.cards = [];

            listado.push(data)
            return {
                ok: true,
                listado: listado
            }
        }

        querySnapshot.forEach( async (documment) => {
            let data = documment.data();
            data.id = documment.id
            data.cliente = limitAndPad(data.cliente, 20);
            data.destino = limitAndPad(data.destino, 20);
            data.qualitat = limitAndPad(data.qualitat, 30);
            data.gramatge = limitAndPad(data.gramatge, 3);
            data.ampleBobina = limitAndPad(data.ampleBobina, 5);
            data.ampleTall = limitAndPad(data.ampleTall, 4);
            data.llargTall = limitAndPad(data.llargTall, 5);
            data.numFulls = limitAndPad(data.numFulls, 7);
            data.kilos = limitAndPad(data.kilos, 7);
            data.dataEntrega = limitAndPad(data.dataEntrega, 5);
            data.numOF = limitAndPad(data.numOF, 6);
            data.observacions = limitAndPad(data.observacions, 30);

            delete data.fechaCreado;
            
            listado.push(data)
        });


        const promises = [];

        let data = {};
        data._id = "1";
        data.ancho = "100%"
        data.title = "Archivados"
        data.cards = listado;

        promises.push(data);


        return {
            ok: true,
            listado:promises
        }

    } catch (error) {
        console.log("error =======>",error)
        return { ok: false, listado: [] }
    }
    

}



export const updateDocument = async(data,ref) => {


    try {
        
        const collectionRef = doc( FirebaseDB, ref);
        await updateDoc(collectionRef, data);
        return {
            ok: true
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}

export const createDocument = async(collectionName,data) => {


    try {
        const collectionRef = doc(collection( FirebaseDB, collectionName ));
        await setDoc(collectionRef, data);

        return {
            ok: true,
            info: collectionRef
        }

    } catch (error) {
        return { ok: false, errorMessage: error.message }
    }

}


export const createReferencia = async(ref) => {


    try {
        const documentRef = doc( FirebaseDB, ref);

        return documentRef

    } catch (error) {
        return null
    }

}