import React from 'react'
import "./Headerbar.css"
import { Avatar, Button } from "@material-ui/core"
import { useStateValue } from '../../utils/Redux/StateProvider';
import { makeStyles } from "@material-ui/core/styles";
import { Layout, Menu, Dropdown } from "antd";
import { useNavigate } from 'react-router-dom'; 


import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as Account } from "../../assets/icons/account.svg";


const useStyles = makeStyles(theme => ({
    topbar: {
        backgroundColor: 'transparent'
    },
    title: {
        flexGrow: 1,
    },
    btn: {
        color: 'white',
        backgroundColor: '#00bb7d',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0C2D48'
        },
        cursor: 'pointer',
        fontSize: '12px',
        height: '36px'
    }
}))


const Headerbar = ({ setOpenMenu }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [{ user }, dispatch] = useStateValue();


    const logOut = () =>{
        localStorage.removeItem('DBUSER');
        localStorage.removeItem('FIREBASEUSER'); 
        window.location.reload(true);
    }

    const goHome = () => {
        navigate('/'); // Usar navigate para ir a home
    }
    
    const goArchivadas = () => {
        navigate('/archivados');
    }

    const menu = (
        <Menu>
          <Menu.Item onClick={goHome}>
            Inicio
          </Menu.Item>
          <Menu.Item onClick={goArchivadas}>
            Tarjetas Finalizadas
          </Menu.Item>
          <Menu.Item onClick={logOut} danger>
            Cerrar sesión
          </Menu.Item>
        </Menu>
      );

    return (
        <div>
            <div className="bottom-bar">
                <button
                    onClick={logOut}>
                    <ExitToAppIcon />
                </button>

                <button
                    onClick={() => setOpenMenu(true)}>
                    <InsertPhotoIcon />
                </button>
            </div>
            <div className='headerContainer'>
                <div className="display">
                    {
                        //<Avatar src={user.photo} />
                        // <h3>{user.name} - Dashboard</h3>
                    }
                  
                </div>


                <div className="btn-container">
                    <Dropdown overlay={menu}>
                    <div className="accountWrap">
                        <span className="account">
                            Administrador
                        </span>
                        <ChevronDown className="chevron"/>
                        <div className="iconAccount">
                        <Account />
                        </div>
                    </div>
                    </Dropdown>
                    

                </div>

               
            </div>
        </div>
        
    )
}

export default Headerbar
