import React, { useState, useEffect } from 'react'
import { makeStyles, fade } from "@material-ui/core/styles";
import axios from "../../utils/Axios"
import {Form, Input, Button, message} from 'antd';

import { useStateValue } from '../../utils/Redux/StateProvider'
import { actionTypes } from '../../utils/Redux/Reducer'
import {ReactComponent as Person} from '../../assets/icons/person.svg';
import {ReactComponent as Lock} from '../../assets/icons/lock.svg';
import { loginWithEmailPassword, logoutFirebase } from '../../firebase/authProvider';
import {signIn, validation} from '../../redux/slices/user';




const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'url(96-960665_desktop-wallpaper-laptop-mac-macbook-air-vk55-andro.jpg)',
        backgroundSize: 'cover'

    },
    loginBox: {
        fontFamily: 'Arial',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: fade('#000', 0.25),
        height: '260px',
        width: '260px',
        padding: theme.spacing(8),
        borderRadius: '8px'
    },
    submit: {
        width: "350px",
        backgroundColor: "#35CA9C",
        borderColor: "#35CA9C",
        fontWeight: 500,
        color: "#FEF2F4",
        borderRadius: "40px",
        height: "46px",
        marginTop: "40px",
        "&:hover, &:focus": {
          backgroundColor: "#35CA9C !important",
          borderColor: "#35CA9C",
        },
    },
    btn: {
        backgroundColor: '#00bb7d',
        color: 'white',
        padding: theme.spacing(1, 1, 1, 1),
        marginTop: theme.spacing(4),
        fontSize: '14px',
        '&:hover': {
            backgroundColor: "#00bb8e"
        }
    },
    image: {
        width: '160px',
        height: 'auto',
        marginBottom: theme.spacing(4)
    },
    info:{
        marginTop: "40px",
        width: "350px",
        padding: "0",
    },
    icon:{
        color: "#000",
        marginRight: "15px",
        marginLeft: "10px"
    },
    input: {
        width: '350px',
        backgroundColor: '#fff',
        border: 'none',
        borderBottom: '1px solid var(--gray)',
        marginBottom: '10px',
        color: '#000 !important',
    },
    input: {
        '& input': {
          fontWeight: 500,
          color: "#000 !important",
        },
    },
    input: {
        '&:hover, &:focus': {
            borderBottom: "1px solid #fff !important",
        },
    },
    form: {
        backgroundColor: '#D6FBF0',
        borderRadius: '5px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '495px',
        height: '515px',
        position: 'relative',
        overflow: 'hidden',
    },

}))



const Login = ({ setUser, setBg }) => {


    const [{ }, dispatch] = useStateValue();
    const [details, setDetails] = useState()
    const [data, setData] = useState({});
    const [registered, setRegistered] = useState(true)
    const classes = useStyles();
    const defaultUrl = "https://i.pinimg.com/originals/47/0a/19/470a19a36904fe200610cc1f41eb00d9.jpg"
    const [sending, handleSending] = useState(false);
    const [code, handleCode] = useState(null);
    const [form] = Form.useForm();



    const signIn = () => {
       /* auth.signInWithPopup(provider)
            .then(res => {

                let email = res.user.email;

                axios.get(`/user/${email}`)
                    .then(response => {
                        if (email === response.data.email) {

                            const userData = {
                                _id: response.data._id,
                                name: response.data.name,
                                email: response.data.email,
                                photo: res.user.photoURL,
                                oldBG: response.data.background
                            }

                            setBg(userData.oldBG)

                            dispatch({
                                type: actionTypes.SET_USER,
                                user: userData,
                            })
                            setUser(userData)
                        }


                        else {
                            const dataObject = {
                                name: res.user.displayName,
                                email: res.user.email,
                                lists: [],
                                background: defaultUrl,
                                photo: res.user.photoURL
                            }
                            setData(dataObject);
                            setRegistered(false)
                        }
                    })
            })
            .catch((error) => alert(error.message))*/
    }

    const handleContinue = () => {

        /*axios.post("/upload/user", data);
        console.log("Uploaded user....no socket io")
        clientSocket.once('user-signed', (userData) => {
            dispatch({
                type: actionTypes.SET_USER,
                user: userData,
            })
            setBg(userData.oldBG)
            setUser(userData)
        })*/

        
    }

    const login = async () => {
        handleSending(true);
        try {
          if (!code) {
            const {user, password} = await form.validateFields();
            if (user.length && password.length) {
    
              const result = await loginWithEmailPassword({ user, password });
                console.log("result",result)
              if (result.ok === true) {
                
                const userData = {
                    _id: result.idUser,
                    name: result.nombre,
                    lastname: result.apellido,
                    email: result.email,
                    photo: "",
                    oldBG: ""
                }

                dispatch({
                    type: actionTypes.SET_USER,
                    user: userData,
                })

                setUser(userData)
                
                //const response = await dispatch(validation(result));
              } else {
                message.error("Email o contraseña incorrectos");
              }
            } else {
              message.error('Ingresa los campos solicitados');
            }
          }
        } catch (errorInfo) {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
        handleSending(false);
      };

    return (
        <div className={classes.root}>

            <div className={classes.loginBox}>
                <img src="/logo_web.png" alt=""
                    className={classes.image} />
                <p style={{ color: 'white' }}>Bienvenido a Proembasa</p>
                <Form
                    className={classes.info}
                    onFinish={login}
                    onFinishFailed={login}
                    autoComplete="off"
                    form={form}
                >

                    <Form.Item
                    name="user"
                    rules={[{ required: true, message: 'Ingresa tu usuario' }]}
                    >
                    <Input
                        prefix={<Person className={classes.icon} />}
                       
                        size="large"
                        className={classes.input}
                        placeholder="Usuario"
                    />
                    </Form.Item>
                    <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Ingresa tu contraseña' }]}
                    >
                    <Input.Password
                        prefix={<Lock className={classes.icon} />}
                        
                        size="large"
                        className={classes.input}
                        visibilityToggle={false} 
                        placeholder="Contraseña"
                    />
                    </Form.Item>
        
                
                    <Form.Item>
                    <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={sending}
                        className={classes.submit}>
                        {code ? 'VERIFICAR' : 'INGRESAR'}
                    </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login
