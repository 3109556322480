import React, { useState, useEffect } from 'react'
import Home from './components/Home'
import { makeStyles, fade } from "@material-ui/core/styles";
import Navbars from './components/NavBar/Navbars';
import StoredApi from './utils/StoredAPI'
import Loader from './components/Loader/Loader';
import Login from './components/Login/Login';
import axios from "./utils/Axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Importa los componentes necesarios de react-router-dom
import Archivados from './components/Archivados';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: "column",
    },
}))

const App = () => {
    const classes = useStyles();
    const trelloUser = JSON.parse(localStorage.getItem('DBUSER'));
    const [user, setUser] = useState(trelloUser)
    const [bg, setBg] = useState("/470a19a36904fe200610cc1f41eb00d9.jpg");
    const [loader, setLoader] = useState(false);
    
    const changeBG = (url) => {
        setLoader(true);
        setBg(url);
        setTimeout(() => {
            setLoader(false)
        }, 3000)
    }

    return (
        <div>
            {!user ? <Login setUser={setUser} setBg={setBg} />
                : (
                    <StoredApi.Provider value={{ changeBG }}>
                        <Router>
                            {loader && <Loader />}
                            <div className={classes.root}
                                style={{
                                    backgroundColor: `${bg}`,
                                    backgroundImage: `url(${bg})`,
                                }}
                            >
                                <Navbars />
                                <Routes>
                                    <Route path="/archivados" element={<Archivados USER={user} />} />
                                    <Route path="/" element={<Home USER={user}/>} />
                                </Routes>
                            </div>
                        </Router>
                    </StoredApi.Provider>
                )}
        </div>
    )
}

export default App
