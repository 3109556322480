// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, writeBatch } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyD3DHD6ohbAdx6atR3SQBmvq4tPPsKeUuc",
    authDomain: "proembasa-b9ded.firebaseapp.com",
    databaseURL: "https://proembasa-b9ded.firebaseapp.com",
    projectId: "proembasa-b9ded",
    storageBucket: "proembasa-b9ded.appspot.com",
    messagingSenderId: "961047290615",
    appId: "1:961047290615:web:8fc1fceac1f2a103c969f2",
};

// Initialize Firebase
export const FirebaseApp  = initializeApp(firebaseConfig);
export const FirebaseAuth = getAuth( FirebaseApp );
export const FirebaseDB   = getFirestore( FirebaseApp );
export const FirebaseStorage = getStorage(FirebaseApp);
export const FirebaseBatch = writeBatch;
